<template>
  <div>
    <v-row class="mt-4 mx-4">
      <v-col cols="12" md="2">
        <date-picker label="From" v-model="fromDate"></date-picker>
      </v-col>
      <v-col cols="12" md="2">
        <date-picker label="To" v-model="toDate"></date-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="primary" class="mt-4" dark @click="refresh">
          <v-icon>mdi-refresh </v-icon>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="2">
        <v-btn color="primary" class="mt-4" :loading="syncing" :disabled="syncing" @click="syncEzr">
          Sync with Routing
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Time</th>
                <th class="text-left">User</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in auditList" :key="item.id">
                <td>{{ format(new Date(item.timestamp * 1000), 'PP p') }}</td>
                <td>{{ getUser(item) }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format, subDays } from 'date-fns';
import DatePicker from '@/components/DatePicker.vue';
import adminApi from '@/apis/admin.js';
import { GET_SYNC_AUDIT_HISTORY } from '@/store/modules/System/actions';

export default {
  name: 'EZRSyncLogs',
  inject: ['eventHub'],
  components: { DatePicker },
  data() {
    return {
      format,
      auditList: [],
      fromDate: '',
      toDate: '',
      syncing: false,
    };
  },
  computed: {
    ...mapGetters('app', ['client', 'clientConfig']),
    ...mapGetters('user', ['me', 'usersByEmail', 'usersById']),
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('system', [GET_SYNC_AUDIT_HISTORY]),
    ...mapActions('user', ['getUsers']),
    async fetchItems() {
      this.toDate = format(new Date(), 'MMM d, yyyy');
      this.fromDate = format(subDays(new Date(), 7), 'MMM d, yyyy');
      await this.getUsers();
      this.refresh();
    },
    async refresh() {
      this.auditList = await this.getSyncAuditHistory({ fromDate: this.fromDate, toDate: this.toDate });
    },
    async syncEzr() {
      const ok = await this.$myconfirm(`Are you sure you want to sync with EZR?`);
      if (ok) {
        this.syncing = true;
        try {
          this.$myalert.warning('Sync initiated - this may take a moment');
          const r = await adminApi.syncEzr(this.client);
          if (r.done) this.$myalert.success('Sync completed!');
        } catch (e) {
          this.$myalert.error('Sync failed');
        }
        this.syncing = false;
      }
    },
    getUser(item) {
      if (item.userId === 0) return 'System';
      else if (item.userId) return `${this.usersById[item.userId].displayName}  (${this.usersById[item.userId].email})`;
      else if (item.username) return `${this.usersByEmail[item.username].displayName}  (${item.username})`;
    },
  },
  watch: {},
};
</script>

<style lang="scss"></style>
