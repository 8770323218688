<template>
  <v-container fluid>
    <v-row dense>
      <v-col class="d-flex" cols="12" sm="4">
        <v-select v-model="activeTable" :items="tables" item-text="name" item-value="value" dense outlined></v-select>
      </v-col>
      <v-col class="d-flex" cols="12" sm="4">
        <v-btn dark color="primary" @click="getSyncMap">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4">
        <v-scroll-x-transition>
          <v-btn v-show="selected.length" dark color="red" @click="deleteItems" class="float-right">
            <v-icon>mdi-delete</v-icon>
            Delete {{ selected.length }} Map{{ selected.length > 1 ? 's' : '' }}
          </v-btn>
        </v-scroll-x-transition>
      </v-col>
    </v-row>

    <v-data-table
      v-model="selected"
      dense
      :headers="headers"
      :items="items[activeTable]"
      item-key="id"
      show-select
      :footer-props="{
        itemsPerPageOptions: [50, 100, 150],
      }"
      class="elevation-1"
    >
      <template #[`item.name`]="{ item }">
        <p class="mb-0">{{ getItemName(item.type, item.ttId) }}</p>
      </template>
      <template #[`item.timestamp`]="{ item }">
        <p class="mb-0">{{ format(new Date(item.timestamp * 1000), 'PP p') }}</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import adminApi from '@/apis/admin.js';

export default {
  data() {
    return {
      format,
      syncMap: [],
      items: {
        addresses: [],
        contacts: [],
        drivers: [],
        schools: [],
        semesters: [],
        stops: [],
        vehicles: [],
        vehicleTypes: [],
      },
      selected: [],
      activeTable: 'drivers',
      tables: [
        { name: 'Addresses', value: 'addresses' },
        { name: 'Contacts', value: 'contacts' },
        { name: 'Drivers', value: 'drivers' },
        { name: 'Schools', value: 'schools' },
        { name: 'Semesters', value: 'semesters' },
        { name: 'Stops', value: 'stops' },
        { name: 'Vehicles', value: 'vehicles' },
        { name: 'Vehicle Types', value: 'vehicleTypes' },
      ],
      headers: [
        { text: 'EZAT ID', value: 'ttId' },
        { text: 'Name', value: 'name' },
        { text: 'EZR ID', value: 'ezrId' },
        { text: 'Last Synced', value: 'timestamp' },
      ],
    };
  },
  computed: {
    ...mapGetters('address', ['address', 'addressesById']),
    ...mapGetters('contact', ['contacts', 'contactsById']),
    ...mapGetters('driver', ['drivers', 'driversById']),
    ...mapGetters('location', ['locations', 'locationsById', 'schoolsById', 'depotsById']),
    ...mapGetters('semester', ['semesters', 'semestersById']),
    ...mapGetters('vehicle', ['vehicles', 'vehiclesById']),
    ...mapGetters('vehicleType', ['vehicleTypes', 'vehicleTypesById']),
  },
  created() {
    this.getSyncMap();
  },
  methods: {
    // ...mapActions('admin', [])
    async getSyncMap() {
      this.syncMap = await adminApi.getSyncMap();
      this.items.addresses = this.syncMap.filter((e) => e.type === 'address');
      this.items.contacts = this.syncMap.filter((e) => e.type === 'contact');
      this.items.drivers = this.syncMap.filter((e) => e.type === 'staff');
      this.items.schools = this.syncMap.filter((e) => e.type === 'school');
      this.items.semesters = this.syncMap.filter((e) => e.type === 'semester');
      this.items.stops = this.syncMap.filter((e) => e.type === 'stop');
      this.items.vehicles = this.syncMap.filter((e) => e.type === 'vehicle');
      this.items.vehicleTypes = this.syncMap.filter((e) => e.type === 'vehicletype');
      console.log(this.items);
    },
    async deleteItems() {
      console.log(this.selected);
      const ok = await this.$myconfirm(
        `Are you sure you want to delete ${this.selected.length} map(s)? This cannot be undone.`
      );
      if (!ok) return;
      await adminApi.deleteSyncMap(this.selected.map((e) => e.id));
    },
    getItemName(type, id) {
      switch (type) {
        case 'staff':
          return `${this.driversById[id]?.firstName} ${this.driversById[id]?.lastName}` || '';
        case 'school':
          return this.schoolsById[id]?.name || '';
        case 'semester':
          return this.semestersById[id]?.name || '';
        case 'stop':
          return this.depotsById[id]?.name || '';
        case 'vehicle':
          return this.vehiclesById[id]?.name || '';
        case 'vehicletype':
          return this.vehicleTypesById[id]?.name || '';
      }
    },
  },
};
</script>

<style scoped></style>
