<template>
  <v-toolbar-items class="w-full">
    <v-btn
      text
      class="nav-button"
      :color="currentPage == 'logs' ? 'primary' : ''"
      @click="$emit('setCurrentPage', 'logs')"
    >
      Logs
    </v-btn>
    <v-btn
      text
      class="nav-button"
      :color="currentPage == 'sync-map' ? 'primary' : ''"
      @click="$emit('setCurrentPage', 'sync-map')"
    >
      Sync Map
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn
      text
      class="nav-button"
      :color="currentPage == 'docs' ? 'primary' : ''"
      @click="$emit('setCurrentPage', 'docs')"
    >
      Docs
    </v-btn>
  </v-toolbar-items>
</template>

<script>
export default {
  name: 'EZRSyncMenu',
  props: { currentPage: String },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.nav-button {
  margin: 0;
}
.w-full {
  width: 100%;
}
</style>
