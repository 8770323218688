<template>
  <v-container fluid>
    <v-toolbar height="50" class="mx-6 mb-2 mt-2" elevation="4" rounded>
      <EZRSyncMenu :currentPage="currentPage" @setCurrentPage="setCurrentPage" />
    </v-toolbar>

    <v-row dense class="mx-6 mt-2">
      <v-col cols="12" class="d-flex flex-column justify-center">
        <Logs v-show="currentPage == 'logs'" />
        <SyncMap v-show="currentPage == 'sync-map'" />
        <Docs v-show="currentPage == 'docs'" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EZRSyncMenu from './Menu.vue';
import Logs from './Logs.vue';
import SyncMap from './SyncMap.vue';
import Docs from './Docs.vue';

export default {
  components: { EZRSyncMenu, Logs, SyncMap, Docs },
  data() {
    return {
      currentPage: 'logs',
    };
  },
  methods: {
    setCurrentPage(page) {
      this.currentPage = page;
    },
  },
};
</script>
